import { Helmet } from "react-helmet"
import React, { Component } from "react"
import HeroImage from '../components/3.1_dosing/HeroImage'
import Main from '../components/3.1_dosing/Main'

import Header from "../components/header/Header"
import ISI from '../components/isi/ISI'
import Footer from '../components/footer/Footer'
import Modal from '../components/modal/Modal'
import HomeModal from '../components/modal/HomeModal'

import SEO from "../components/seo"

import Loader from "../components/loader"


export default class Dosing extends Component {

  constructor(props) {
    super(props)
    this.state = {
       stickyNav: false,
       isMobile: false,
       modal:  null
    }
  }

  componentDidMount() {
    let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;
    this.setState({ isMobile: bool })
  }

  handleClick(e) {
    // console.log('clicked');
  }

  goModal = (evt) => {
    this.setState(() => ({ modal: evt }))
  }

  modalClosed = () => {
    this.setState(() => ({ modal: null }))
  }


  render() {

    return (
      <>
        <Loader>
          <SEO
            title="Dosing"
            keywords={[""]}
            description="Two ways to prescribe VASCEPA: Dosing is 4 grams per day, and given BID with food. Available in 1-g and 0.5-g capsules."
          />
          <HomeModal />

          <Modal modal={this.state.modal} modalClosed={this.modalClosed} />
          <Header
            isMobile={this.state.isMobile}
            hideNav={this.state.hideNav}
            goModal={this.goModal}
          />
          <div className="grad"></div>
          <HeroImage onClick={this.handleClick} bckgrdImage={"header3-1"} />
          <div className="wrapper">
            <Main isMobile={this.state.isMobile} goModal={this.goModal} />
          </div>
          <ISI
            scrollPos={this.state.unstick}
            disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product."
          />
          <div className="ref-outer">
            <div className="footnotes-container">
              <div className="references">
                <p className="cv-footnote" style={{marginBottom: "0px"}}>
                  BID= twice daily; CV=cardiovascular.
                </p>

                <p className="cv-footnote" style={{marginTop: "10px"}}>
                  <strong>Reference: 1.</strong>  VASCEPA [package insert]. Bridgewater, NJ: Amarin Pharma, Inc.; 2021.
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </Loader>
      </>
    )
  }
}
