
import React from "react"
import {Link} from "gatsby"

import './styles.css'

export default (props) => {

    return (
      <main className="center-grid" style={{marginBottom: "30px", marginTop:"30px"}}>
        <section className="endpoints">
          <h2 className="cv-headline">
            There are <strong>two ways</strong> to prescribe VASCEPA 4 g/d
            <sup>1</sup>:
          </h2>

          <div className="dosing-flex-container">
            <div className="dosing-f-box">
              <p><strong>Two</strong> 1-g capsules<br/> BID with food</p>
            </div>
            <div className="dosing-c-box">
              <p><strong>OR</strong></p>
            </div>
            <div className="dosing-f-box">
              <p><strong>Four</strong> 0.5-g capsules<br/> BID with food</p>
            </div>
            {/* <div style={{ width: "100%" }}>
              <h2 className="cv-headline violet">
                VASCEPA<sup>&reg;</sup> (icosapent ethyl)
              </h2>
              <ul className="content-list">
                <li className="primary-list-item">
                  In addition to severe hypertriglyceridemia,{" "}
                  <strong>
                    VASCEPA is indicated for cardiovascular risk reduction
                  </strong>
                  —as an adjunct to maximally tolerated statin therapy to reduce
                  the risk of MI, stroke, coronary revascularization, and
                  unstable angina requiring hospitalization in adult patients
                  with TG ≥150 mg/dL and established CVD or diabetes and ≥2 CVD
                  risk factors<sup className="sup-10">1</sup>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="cv-headline violet">Generic icosapent ethyl</h2>
              <ul className="content-list">
                <li className="primary-list-item">
                  <strong>Not indicated to reduce cardiovascular risk</strong>
                  —only indicated as an adjunct to diet to reduce triglyceride
                  levels in adult patients with{" "}
                  <strong>severe (≥500 mg/dL) hypertriglyceridemia</strong>
                  <sup className="sup-10">2,3</sup>
                </li>
                <li className="primary-list-item">
                  <strong>Supply of the generic is limited</strong>
                  <sup className="sup-10">3</sup>
                </li>
              </ul>
            </div> */}
          </div>
          <div className="dosing-bullet-container">
            <ul className="content-list">
                <li className="primary-list-item">
                  Prescribe VASCEPA 4 g/d, the same dosing used in the VASCEPA CV Outcomes Trial (REDUCE-IT<sup className="sup-10">&reg;</sup>)<sup className="sup-10">1</sup>
                </li>
                <li className="primary-list-item">
                  Patients should be advised to swallow VASCEPA capsules whole, not to break open, crush, dissolve, or chew VASCEPA
                </li>
              </ul>
          </div>
          
          <article className="">
                <div className="dosing-grad-border">
                    <p className="callout-copy">Indicate "Dispense as written (DAW-1)" or "Brand medically necessary" to ensure
                  patients are getting the CV benefits of VASCEPA<sup className="sup-10 callout-sup">1</sup></p>
                </div>
            </article>
            <div className="dosing-callout">
              <div className="cta-container m-center fit-content">
                <h3  className="cta-copy">VASCEPA may be affordable for most patients</h3>
                <a href="/access-and-coverage/" className="cta-btn">See how</a>
              </div>
            </div>
        </section>
      </main>
    )
}
